.myModals{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.details{
    position: relative;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
}
.productview{
    position: relative;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.productimage{
    position: relative;
    border-radius: 10px;
    background-color: #FFFFFF;
    object-fit: contain;
    /* width: 500px; */
    height: 500px;
}
.productspecs{
    position: relative;
    border-radius: 10px;
    padding: 20px;
    background-color: #343434;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.dimension{
    position: relative;
    width: auto;
    height: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    /* right: 350px; */
}
.material{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.color{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* right:500px; */
}
.Style{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.reference{
    position: relative;
    width: 300px;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* right: 403px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    
}

.productdetails{
    position: relative;
    display: flex;
    width: auto;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    height: auto;
}
.about{
    position: relative;
    width: 250px;
    display: flex;
    border-radius: 10px;
    background-color: #343434;
    padding: 10px 10px 10px 20px;
    flex-direction: column;
    gap: 15px;
    color: white;
    font-size: medium;
    height: auto;
}
.productname{
    position: relative;
    width: auto;
    height: auto;
    /* right: 427px; */
}
.name{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.brandname{
    position: relative;
    width: auto;
    height: auto;
    text-align: left;
    /* right: 455px; */
}
.brand{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.productId{
    position: relative;
    width: auto;
    height: auto;
    /* right: 487px; */
}
.Id{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.productlevel{
    position: relative;
    width: auto;
    height: auto;
    border-radius: 10px;
    display: flex;
    padding: 10px 10px 10px 20px;
    flex-direction: row;
    gap: 20px;
    color: #FFFFFF;
    font-size: medium;
}
.level{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.types{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.priority{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.variant{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 100px;
    border-radius: 10px;
    padding: 10px 10px 10px 20px;
}
.varientmodel{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.variantlist{
    position: relative;
    width: auto;
    padding: 35px 10px 10px 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.productvarient{
    position: relative;
    width: auto;
    padding: 10px 10px 10px 20px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    background-color: #343434;
    gap: 20px;
}
.checkbox{
    position: relative;
    width: 20px;
    height: 20px;
    top: 0px;
}
.ProductImage{
    position: relative;
    width: 100px;
    height: 100px;
    top: -30px;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.ProductDetails{
    position: relative;
    display: flex;
    justify-items: center;
    flex-direction: column;
    gap: 20px;
}

