.roomview{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.search{
    position: relative;
    width: 100%;
    padding-left: 750px;
    color: white;
}
.page{
    position: relative;
    display: flex;
    flex-direction: column;
}
.gridcontainer{
    position: relative;
    width: 100%;
    height: auto;
    top: 50px;
    padding: 20px 20px 20px 20px;
}
.gridlist{
    position: relative;
    padding: 10px 10px 10px 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 50px;
    column-gap: 25px;
}
.griditem{
    position: relative;
    width: 325px;
    padding: 0px 0px 15px 0px;
    color: white;
    background-color: #373737;
    background: linear-gradient(237.71deg, #343434 0%, rgba(52, 52, 52, 0.65) 97.88%);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    height: auto;
    display: flex;
    flex-direction: column;
}
.content{
    position: relative;
    display: flex;
    flex-direction: column;
}
.image{
    object-fit: contain;
    position: relative;
    width: 240px;
    height: 160px !important;
    border-radius: 6px;
    background-color: white;
    top: -30px;
    border: 1px solid white;
}
.roomname{
    position: relative;
    width: 100%;
    text-align: left;
    top: -15px;
}