.myModals{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.details{
    position: relative;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
}
.productview{
    position: relative;
    width: auto;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.productimage{
    position: relative;
    border-radius: 10px;
    background-color: #FFFFFF;
    width: 400px;
    height: 400px;
}
.productspecs{
    position: relative;
    border-radius: 10px;
    padding: 20px;
    background: radial-gradient(50% 50% at 50% 50%, #303030 0%, rgba(35, 35, 35, 0.42) 100%);
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.dimension{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.material{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.color{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.Style{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.reference{
    position: relative;
    width: auto;
    height: 35px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.productdetails{
    position: relative;
    display: flex;
    width: auto;
    flex-direction: column;
    padding-top: 20px;
    gap: 20px;
    left: -50px;
    height: auto;
}
.about{
    position: relative;
    width: 250px;
    display: flex;
    background: radial-gradient(50% 50% at 50% 50%, #303030 0%, rgba(35, 35, 35, 0.69) 100%);
    border-radius: 10px;
    padding: 10px 10px 10px 20px;
    flex-direction: column;
    gap: 15px;
    color: white;
    font-size: medium;
    height: auto;
}
.productname{
    position: relative;
    width: auto;
    height: auto;
}
.name{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.brandname{
    position: relative;
    width: auto;
    height: auto;
}
.brand{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.productId{
    position: relative;
    width: auto;
    height: auto;
}
.Id{
    position: relative;
    width: auto;
    height: auto;
    left: 10px;
}
.productlevel{
    position: relative;
    width: 500px;
    height: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0px;
    flex-direction: row;
    gap: 20px;
    color: #FFFFFF;
    font-size: medium;
}
.levels{
    position: relative;
    width: 100px;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.types{
    position: relative;
    width: 180px;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.priority{
    position: relative;
    width: 180px;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.variant{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 100px;
    border-radius: 10px;
    padding: 10px 10px 10px 0px;
}
.varientmodel{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.variantlist{
    position: relative;
    width: auto;
    padding: 25px 10px 10px 0px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-y:scroll;
}
.productvarient{
    position: relative;
    width: auto;
    padding-left: 20px;
    padding: 10px 10px 10px 20px;
    height: 85px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 10px;
    background: linear-gradient(270.05deg, #343434 0.04%, rgba(52, 52, 52, 0) 131.91%);
    gap: 20px;
}
.checkbox{
    position: relative;
    width: 20px;
    height: 20px;
    top: 0px;
}
.ProductImage{
    position: relative;
    width: 100px;
    height: 100px;
    top: -40px;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.ProductDetails{
    position: relative;
    display: flex;
    justify-items: center;
    flex-direction: column;
    gap: 20px;
}
.uploadbutton{
    position: relative;
    font-size: medium;
    padding-left: 10px;
}
.uploadbutton:hover{
    text-decoration: underline;
    cursor: pointer;
}
.files{
    position: relative;
    width: auto;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: x-large;
    background: radial-gradient(50% 50% at 50% 50%, #303030 0%, rgba(35, 35, 35, 0.27) 100%);
    border-radius: 5px;
}
.Heading{
    position: relative;
    display: flex;
    flex-direction: column;
    /* text-align: center;
    align-items: center; */
    gap: 20px;
}
.filenames{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 130px;
    width: auto;
}
.righthead{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: auto;
}
.filelist{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 35px;
    width: max-content;
}
.uploadfiles{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
}
.filedetails{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
    gap: 45px;
}
.file{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: auto;
}
.date{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: auto;
}
.size{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: auto;
}
.status{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: auto;
}