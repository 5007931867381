.dash{
    position: relative;
    display: flex;
    background-color: #171717;
}
.home{
    background-color: #171717;
    width: 100%;
}
.welcome{
    position: relative;
    display: flex;
    /* gap: 450px; */
    flex-direction: row;
    /* padding-left: 50px; */
    /* padding-top: 35px; */
    font-size:x-large;
    color: #FFFFFF;
}
.progress{
    position: relative;
    color: #FFFFFF;
    font-size: medium;
    padding-left: 50px;
    padding-top: 5px;
}
.topbar{
    position: relative;
    height: 120px;
    border-bottom: 1px solid gray
}
.top{
    position: relative;
    align-items: flex-end;
}