.assetsviewpage{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    color: white;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.assetview{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 25px;
}
.assetimageview{
    position: relative;
    width: max-content;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: teal;
    border-radius: 10px;
    object-fit: contain;
}
.assetsimilar{
    position: relative;
    display: grid;
    padding: 30px 10px 10px 0px;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    font-weight: normal;
    font-size: large;
    row-gap: 30px;
}
.assetsimilarlist{
    position: relative;
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.similarimage{
    position: relative;
    width: max-content;
    height: auto;
    display: flex;
    align-items: center;
    background-color: teal;
    border-radius: 10px;
}
.similarview{
    position: relative;
    width: 200px;
    height: 200px;
}
.similarview:hover{
    scale: 1.2;
}