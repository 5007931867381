.roomviewDetail{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.roomview{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 10px 10px 10px 30px;
}
.roomviewtop{
    color: white;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.roomimage{
    position: relative;
    width: 550px;
    height: 400px;
    border-radius: 10px;
    background-color: white;
}
.roominfo{
    position: relative;
    width: 400px;
    padding: 0px 10px 10px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    gap: 20px;
}
.roomdetails{
    position: relative;
    width: 350px;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: left;
    background-color: #242323 ;
    border-radius: 10px;
    padding: 5px 5px 5px 15px;
}
.roomname{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.roomstyle{
    position: relative;
    display: flex;
    flex-direction: row;
}
.roomtype{
    position: relative;
    display: flex;
    flex-direction: row;
}
.texturelist{
    position: relative;
    width: 100%;
    padding-left: 10px;
    font-size: large;
    display: flex;
    flex-direction: column;
}
.textures{
    position: relative;
    padding-top: 15px;
    display: grid;
    row-gap: 20px;
    column-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
}
.texturedetails{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: medium;
}
.roomviewbottom{
    color: white;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.productlistgrid{
    position: relative;
    width: 100%;
    padding: 10px 10px 10px 0px;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
}
.roomproductlist{
    position: relative;
    width: 500px;
    height: 100px;
    border-radius: 10px;
    background-color: #242323;
}
.products{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 10px 10px 25px;
    flex-direction: row;
    gap: 80px;
}
.image{
    position: relative;
    top: -20px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-color: white;
}
.details{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.productdetails{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.status{
    /* position: relative; */
    width: 180px;
    height: 30px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: large;
    border-radius: 50vh;
    color: #171717;
    font-weight: 700;
    background-color: #F2D399;
    border: 4px solid #E29525;
}
.statusstate{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
}