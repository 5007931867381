.myModals{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.filters{
    position: relative;
    display: flex;
    padding-left: 25px;
    padding-bottom: 30px;
    flex-direction: row;
    color: white;
    font-size: large;
    gap: 240px;
}
.filterbox{
    position: relative;
    background-color:#343434;
    width: 120px;
    display:flex;
    height: 30px;
    border-radius: 2px;
}
.filtersbox{
    position: relative;
    display: flex;
    gap: 20px;
}
.filtername{
    position: relative;
    width: 100%;
    text-align: center;
    align-items: center;
    align-self: center;
}
.search{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 250px;
    height: 30px;
    border-radius: 5px;
    background-color: #343434;
    align-items: center;
}
.products{
    position: relative;
    display: flex;
    max-width: 100%;
    height:auto;
    padding-top: 50px;
    margin-left: 25px;
    flex-direction: row;
    color: white;
    font-family: 'Quicksand' !important;
    font-size: large;
}
.product{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 70px;
    /* width: 1028px; */
    /* height: 70px; */
    border-radius: 5px;
    background-color: #343434;
}
.productImage{
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    top: -40px;
    left: 10px;
    border-radius: 5px;
    background-color: white;
}
.productName{
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
}
.productdetails{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}
.productlevel{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.priority{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.varient{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.modalstatus{
    position: relative;
    width: 150px;
    display: flex;
    align-items: center;
}
.button{
    /* position: relative; */
    width: 150px;
}
::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}
  
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100vw;
}
  
::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 100vw;
}
  
::-webkit-scrollbar-thumb:hover {
    background: #8e8e8e;
    border-radius: 100vw;
}