.App {
    text-align: center;
    display: flex;
    min-height: 100vh;
    font-family: 'Quicksand' !important;
    position: relative;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
}
.logo{
    position: absolute;
    justify-content: left;
    left: -400px;
    top: -300px;
}

.container{
    position: relative;
    width: 450px;
    height: 300px;
    /* left: -250px; */
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    align-items: center;
}
.heading{
    position: relative;
    font-size: xx-large;
}
.glasscontainer{
    position: relative;
    width: 400px;
    padding: 20px 40px 50px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    background: radial-gradient(115.47% 115.47% at 50% 50%, #343434 0%, rgba(52, 52, 52, 0) 100%);
    border-radius: 15px;
    border: 1px solid #343434;
}
.form{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.email{
    position: relative;
    width: 100%;
    text-align: left;
    font-size: large;
}
.emailinput{
    position: relative;
    width: 100%;
    text-align: left;
}
