.top{
    position: relative;
    width: 100%;
    /* height: 120px; */
    color: white;
    font-size: large;
    font-family: 'Quicksand' !important;
}
.home{
    background-color: #171717;
    width: 100%;
    display: flex;
}
.topbar{
    position: relative;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    gap: 250px;
    padding-left: 50px;
    align-items: center;
    background-color: #171717;
    /* border-bottom: 1px solid gray */

}
.topbar_icons{
    position: relative;
    padding: 10px 0px 0px 0px;
    /* width: 100%; */
    display: flex;
    /* padding-left: 740px; */
}
.icon{
    position: relative;
    display: flex;
    gap: 25px;
}
.profiledrop{
    position: relative;
    width: 100%;
    padding: 5px 20px 5px 5px;
    max-height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #171717;
    border: none;
    flex-direction: row;
    gap: 20px;
}
/* .profiledrop:hover{
    background-color: #343434;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
} */

.dropdown::after{
    display: none !important;
}