.QCcheckPage{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    color: white;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.modelqueuelist{
    position: relative;
    width: 100%;
    height: auto;
    padding: 5px 5px 5px 10px;
    display: flex;
    flex-direction: row;
    gap: 13px;
}
.imagelist{
    position: relative;
    width: max-content;
    height: max-content;
    object-fit: contain;
    /* background-color: white; */
    border-radius: 5px;
    cursor: pointer;
}
.image{
    position: relative;
    width: 60px;
    height: 60px;
}
.image:hover{
    border: 3px solid teal;
}
.qcbottom{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* width: 100%; */
    height: auto;
    padding: 10px 10px 10px 10px;
}
.qcbottomleft{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.productimage{
    position: relative;
    display: flex;
}
.productdetails{
    position: relative;
    /* width: max-content; */
    /* height: auto; */
    background-color: #343434;
    border-radius: 10px;
}
.productdetailsview{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 10px 10px 10px;
}
.details{
    position: relative;
    display: flex;
    flex-direction: column;
}
.heading{
    font-weight: lighter;
}
.value{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: x-large;
}
.qcbottomright{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.modelviewer{
    position: relative;
    display: flex;
}
.qcbuttons{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 180px;
}
.count{
    position: relative;
    width: 150px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #171717;
    font-weight: 700;
    justify-content: center;
    gap: 20px;
    background-color: #77BFB9;
    border-radius: 50vh;
}
.qcstatus{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.fail:hover{
    border: 2px solid black;
}
.pass:hover{
    border: 2px solid black;
}