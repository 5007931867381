.page{
    position: relative;
    display: flex;
    flex-direction: column;
}
.modelgrab{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;

    /* overflow: auto; */
}
.body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.filters{
    position: relative;
    display: flex;
    padding-left: 25px;
    padding-top: 20px;
    flex-direction: row;
    color: white;
    font-size: large;
    gap: 350px;
}
.filterbox{
    position: relative;
    background-color:#343434;
    width: 120px;
    display:flex;
    height: 30px;
    border-radius: 2px;
}
.filtersbox{
    position: relative;
    display: flex;
    gap: 30px;
}
.filtername{
    position: relative;
    width: 100%;
    text-align: center;
    align-items: center;
    align-self: center;
}
.search{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 250px;
    height: 30px;
    border-radius: 5px;
    background-color: #343434;
    align-items: center;
}
.gridContainer{
    position: absolute;
    max-width: 100%;
    height: auto;
    padding-top: 80px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 35px;
    column-gap: 60px;
    row-gap: 80px;
}
.gridlist{
    position: relative;
    width: 260px;
    height: 260px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    background-color: #343434;
    align-items: center;
}
.content{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.imagecontainer{
    position: relative;
    width: 190px;
    height: 190px !important;
    background-color: white;
    border: 1px solid white;
    top: -20px;
    border-radius: 5px;
    /* right: -44px; */
}
.product{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    top: -18px;
    gap: 35px;
    color: white;
    font-family: 'Quicksand' !important;
    text-overflow: ellipsis;
    font-size: 14px;
}
.priority{
    position: relative;
    justify-content: left;
    display: flex;
    flex-direction: row;
    gap:4px;
}
.productName{
    position: relative;
    width: 100px;
    /* display: flex; */
    justify-content: center;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}