.assetspage{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Quicksand' !important;
    flex-direction: row;
    background-color: #171717;
    overflow: hidden !important;
}
.pagebody{
    position: relative;
    width: 100%;
    overflow: auto;
}
.filters{
    position: relative;
    width: 100%;
    display: flex;
    padding-left: 25px;
    flex-direction: row;
    color: white;
    font-size: large;
    align-items: center;
    gap: 900px;
}
.filterbox{
    position: relative;
    background-color:#343434;
    /* width: 100%; */
    display:flex;
    height: 30px;
    border-radius: 2px;
}
.filtersbox{
    position: relative;
    /* width: 380px !important; */
    display: flex;
    gap: 50px;
}
.filtername{
    position: relative;
    width: 100%;
    background: radial-gradient(104.69% 104.69% at 50% 50%, #343434 0%, rgba(0, 0, 0, 0.5) 100%);
    border: none;
    text-align: center;
    align-items: center;
    align-self: center;
}
.search{
    position: absolute;
    display: flex;
    left: 720px;
    flex-direction: row;
    width: 250px;
    height: 30px;
    border-radius: 5px;
    background-color: #343434;
    align-items: center;
}
.assetscontainer{
    position: relative;
    width: 100%;
    top: 10px;
    padding: 20px 25px 10px 25px;
    color: white;
    font-size: larger;
    font-weight:normal;
}
.assetslist{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 15px;
    row-gap: 40px;
}
.assets{
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.assetimage{
    position: relative;
    width: 200px;
    height: 200px;
}
.assetimage:hover{
    scale: 1.1;
}
.image{
    width: auto;
    height: auto;
    border-radius: 10px;
    object-fit: contain;
    background-color: teal;
}
.dropdownitem{
    color: white;
    font-weight: 700;
    font-size: large;
}
.customModal{
    background-color: #343434;
}