@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
.App {
  text-align: center;
  display: flex;
  font-family:'Quicksand' !important;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  overflow-x: hidden;
}
.welcome{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
}
.welcome h2{
  font-weight: normal;
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  position: relative;
  top: 70px;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px 30px;
  gap: 26px;
  background: radial-gradient(115.47% 115.47% at 50% 50%, #343434 0%, rgba(52, 52, 52, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid #343434;
  border-radius: 15px;
}
.logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
  position: absolute;
  width: 150px;
  height: 100px;
  left: 25px;
  top: 25px;
  /* border: 2px solid white; */
  border-radius: 6px;
}
.auth-form-container h2{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.loginform{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 30px;
}
.login-form{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}
.email_input{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 0px;
  padding-bottom: 10px;
}
.password_input{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 0px;
  padding-bottom: 10px;
}
.email_text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.password_text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    /* width: 384px;
    height: 397px; */
    position: relative;
    top: 25px;
    /* left: 135px; */
    border: 1px solid white;
    border-radius: 10px;
  }
}

.lottie{
  box-sizing: border-box;
  display: flex;
  width: 380px;
  height: 397px;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  background: radial-gradient(86.34% 86.34% at 50% 50%, #343434 0%, rgba(52, 52, 52, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid #343434;
  border-radius: 15px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

label {
  text-align: left !important;
  padding: 0.25rem 0;
}

input {
  width: 300px;
  height: 15px;
  top: 20px;
  margin: 0.5rem 0;
  padding: 1rem;
  /* padding-bottom: 10px; */
  border: none;
  background-color: black;
  border-radius: 5px;
}

.button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 100px;
  background: #A0CDDA;
  border: 0.965578px solid #E0D2C3;
  border-radius: 30.8985px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #02113F;
}

.signup{
  border: none;
  position: relative;
  width: 100px;
  top: 5px;
  font-size: large;
  font-weight: 700;
  align-self: center;
  background-color: gray;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

.link-btn {
  position: relative;
  height: 35px;
  top: 20px;
  background: none;
  color: black;
  text-decoration: underline;
}
.forgot_password{
  position: relative;
  /* top: 40px; */
  font-size: medium;
}
.forgot_password:hover{
  text-decoration: underline;
  cursor: pointer;
}